import { render, staticRenderFns } from "./JobOpportunities.vue?vue&type=template&id=300c3ffc&scoped=true&"
import script from "./JobOpportunities.vue?vue&type=script&lang=ts&"
export * from "./JobOpportunities.vue?vue&type=script&lang=ts&"
import style0 from "./JobOpportunities.vue?vue&type=style&index=0&id=300c3ffc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "300c3ffc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VIcon,VImg,VList,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VMenu,VSpacer})
