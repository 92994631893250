
























































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { VacancyModel } from "@/models/Vacancy";
import { VacancyService } from "@/services/Public";
import CardLink from "@/views/shared/CardLink.vue";
import LocationMarker from "@/views/shared/LocationMarker.vue";
import RemindToApplyDialog from "@/components/dialogs/RemindToApplyDialog.vue";
import { ECompensationType } from "@/models/enums";

@Component({
  name: "JobOpportunities",
  components: {
    CardLink,
    LocationMarker,
    RemindToApplyDialog,
  },
})
export default class extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  public readonly data!: VacancyModel[];

  @Prop({ required: false, default: 2 })
  public readonly showCount!: number;

  @Prop({ required: true, default: false })
  public readonly isCandidate!: boolean;

  private sortNames = {
    newest: "Newest first",
    oldest: "Oldest first",
  };

  private sortOrder = this.sortNames.newest;

  private showMore = false;

  private openSort = false;

  private remindingVacancyId = "";

  private showRemindDialog = false;

  get opportunities() {
    let items = [...this.data];

    if (this.sortOrder === this.sortNames.newest) {
      items.sort(
        (a, b) =>
          new Date(b.dateOfCreation).getTime() -
          new Date(a.dateOfCreation).getTime()
      );
    } else {
      items.sort(
        (a, b) =>
          new Date(a.dateOfCreation).getTime() -
          new Date(b.dateOfCreation).getTime()
      );
    }

    return items.length <= this.showCount || this.showMore
      ? items
      : items.splice(0, this.showCount);
  }

  getCompensation(vacancy: VacancyModel) {
    let compensation = "";

    if (vacancy.compensationType === ECompensationType.Salary) {
      compensation = `$${vacancy.compensationSalary}`;
      compensation =
        vacancy.compensationSalaryMax > vacancy.compensationSalary
          ? `${compensation} - $${vacancy.compensationSalaryMax}/year`
          : `${compensation}/year`;
    } else {
      compensation = `$${vacancy.compensationHourly}`;
      compensation =
        vacancy.compensationHourlyMax > vacancy.compensationHourly
          ? `${compensation} - $${vacancy.compensationHourlyMax}/hour`
          : `${compensation}/hour`;
    }

    return compensation;
  }

  getOpportunityAvatar(item: VacancyModel) {
    return item.cover
      ? item.cover.resizedUrl
      : require("@/assets/no-avatar-job-opportunity.svg");
  }

  remindAction(id: string) {
    this.showRemindDialog = true;
    this.remindingVacancyId = id;
  }

  async saveAction(id: string) {
    try {
      await VacancyService.save(id);
      this.$toast.success("Opportunity successfully saved!");
    } catch (e) {
      console.error("Save error: ", e);
      this.$toast.error("Opportunity saving error!");
    }
  }

  shareAction(link: string) {
    // Can't use clipboard API, because not supported on macOS (tested on Mojave)
    const dummy = document.createElement("input");

    document.body.appendChild(dummy);
    dummy.value = link;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    this.$toast.success("Opportunity link copied to the clipboard! ");
  }
}
