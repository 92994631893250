











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OpprotunityService } from "@/services/Search";
import { ECompensationType } from "@/models/enums";
import { VacancyModel } from "@/models/Vacancy";
import { OpportunityListItemModel } from "@/models/common";
import OpportunitiesList from "@/components/lists/OpportunitiesList/index.vue";
import { SimilarOpportunitiesRequestModel } from "@/models/requests/Opportunities";

@Component({
  name: "SimilarOpportunities",
  components: {
    OpportunitiesList,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: "" })
  public readonly opportunityId!: string;

  @Prop({ required: false, default: "" })
  public readonly companyId!: string;

  @Prop({ required: false, default: "" })
  public readonly title!: string;

  private items: OpportunityListItemModel[] = [];

  private loading = false;

  private initLoading = false;

  private page = 1;

  private pageSize = 4;

  private showMore = true;

  async mounted() {
    try {
      this.initLoading = true;
      await this.load();
    } catch (e) {
      console.log("More opportunities fetch error: ", e);
    } finally {
      this.initLoading = false;
    }
  }

  getCompensation(vacancy: VacancyModel) {
    let compensation = "";

    if (vacancy.compensationType === ECompensationType.Salary) {
      if (vacancy.compensationSalary <= 0) return "";
      compensation = `$${vacancy.compensationSalary.toLocaleString()}`;
      compensation =
        vacancy.compensationSalaryMax > vacancy.compensationSalary
          ? `${compensation} - $${vacancy.compensationSalaryMax.toLocaleString()}/year`
          : `${compensation}/year`;
    } else {
      if (vacancy.compensationHourly <= 0) return "";
      compensation = `$${vacancy.compensationHourly.toLocaleString()}`;
      compensation =
        vacancy.compensationHourlyMax > vacancy.compensationHourly
          ? `${compensation} - $${vacancy.compensationHourlyMax.toLocaleString()}/hour`
          : `${compensation}/hour`;
    }

    return compensation;
  }

  async load() {
    try {
      this.loading = true;
      const searchQuery: SimilarOpportunitiesRequestModel = {
        page: this.page,
        pageSize: this.pageSize,
      };

      if (this.opportunityId) {
        searchQuery.opportunityId = this.opportunityId;
      } else {
        searchQuery.companyId = this.companyId;
      }

      const response = await OpprotunityService.similar(searchQuery);

      const mappedResponse = response.items.map((x: VacancyModel) => ({
        image: x.cover?.resizedUrl,
        title: x.title,
        location: x.location,
        link: x.link,
        salary: this.getCompensation(x),
      }));

      this.items = this.items.concat(mappedResponse);
      this.page = this.page + 1;

      if (response.currentPage === response.totalPages) {
        this.showMore = false;
      }
    } finally {
      this.loading = false;
    }
  }
}
