

















































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver, setInteractionMode, extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";
import { VacancyService } from "@/services/Public";
import TextField from "@/components/inputs/TextField/index.vue";
import DateTimePicker from "@/components/inputs/DateTimePicker/index.vue";
import DialogWrapper from "@/views/shared/DialogWrapper.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: `This field is required.`,
});

extend("email", {
  ...email,
  message: `Email is not valid.`,
});

interface FormModel {
  firstName: string;
  email: string;
  date: Date;
}

@Component({
  name: "RemindToApplyDialog",
  components: {
    ValidationObserver,
    DialogWrapper,
    TextField,
    DateTimePicker,
  },
})
export default class RemindToApplyDialog extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: true, default: "" })
  public readonly vacancyId!: string;

  @Prop({ required: true, default: false })
  public readonly isAnon!: boolean;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  private loading = false;

  private formData: FormModel = {
    firstName: "",
    email: "",
    date: new Date(),
  };

  get startDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
  }

  @Watch("value")
  watchValue(value: boolean) {
    if (value) {
      this.formData = {
        firstName: "",
        email: "",
        date: this.startDate,
      };
      this.$refs.observer.reset();
    }
  }

  isStartDateDisabled(date: Date) {
    const currentDate = new Date();
    return date.toISOString() < currentDate.toISOString();
  }

  async remind() {
    const formIsValid = await this.$refs.observer.validate();
    if (!formIsValid) return;

    try {
      this.loading = true;

      if (this.isAnon) {
        await VacancyService.remindToApplyAnon({
          vacancyId: this.vacancyId,
          firstName: this.formData.firstName,
          email: this.formData.email,
          remindDateTime: this.formData.date,
        });
      } else {
        await VacancyService.remindToApply(this.vacancyId, this.formData.date);
      }

      this.input(false);
      this.$toast.success("Reminder successfully created.");
    } catch (e) {
      this.$toast.error("Reminder creation error!");
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
